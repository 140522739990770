<template>
    <div>add new user</div>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';

export default {
    components: {},

    mixins: [ModalNavigation],

    methods: {},
};
</script>

<style scoped></style>
